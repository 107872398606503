import React, { useState } from "react"
import { Container, Row, Col } from "react-grid-system"
import Slider from "rc-slider"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Subscribe from "../components/index/subscribe"

import "rc-slider/assets/index.css"
import * as styles from "./cennik.module.scss"
import sliderArrows from "../images/slider-arrows.png"

const prices = [
  {
    page: "0,63 PLN",
    pages: (
      <span>
        Do <strong>1000</strong>
      </span>
    ),
    price: (
      <div className={styles.price}>
        390 PLN<span> / mc</span>
      </div>
    ),
  },
  {
    page: "0,50 PLN",
    pages: (
      <span>
        Do <strong>2500</strong>
      </span>
    ),
    price: (
      <div className={styles.price}>
        790 PLN<span> / mc</span>
      </div>
    ),
  },
  {
    page: "0,35 PLN",
    pages: (
      <span>
        Do <strong>5000</strong>
      </span>
    ),
    price: (
      <div className={styles.price}>
        1390 PLN<span> / mc</span>
      </div>
    ),
  },
  {
    page: "do ustalenia",
    pages: <strong>Dowolna ilość</strong>,
    price: <div className={styles.price}>Skontaktuj się</div>,
  },
]

const SliderLabel = props => (
  <div className={styles.sliderLabel}>
    <div className={styles.sliderMarker}>|</div>
    {props.label}
  </div>
)

const Cennik = ({ location }) => {
  const [sliderIdx, setSliderIdx] = useState(0)

  return (
    <Layout location={location.pathname}>
      <SEO title="Cennik" />
      <div className={styles.main}>
        <h1>Cennik</h1>
        <p className={styles.subheadline}>
          Rozpoczęcie pracy z Engrafą jest całkowicie darmowe.
          <br />W dowolnym momencie możesz wybrać plan dostosowany do Twoich
          potrzeb.
        </p>

        <Container justify="center">
          <Row>
            <Col lg={4} className={styles.starter}>
              <div>
                <h2>Starter</h2>
                <div className={styles.price}>
                  0 PLN<span> / mc</span>
                </div>
                <div className={styles.features}>
                  <ul>
                    <li>
                      Do <strong>20</strong> odczytanych stron na miesiąc
                    </li>
                    <li>
                      Każda dodatkowa strona <strong>0,85 PLN</strong>
                    </li>
                    <li>
                      <strong>Nieograniczona</strong> ilość użytkowników
                    </li>
                    <li>
                      <strong>Nieograniczona</strong> ilość podmiotów
                    </li>
                    <li>Możliwość eksportu do programów FK</li>
                    <li>Wyszukiwanie dokumentów do 6 miesięcy wstecz</li>
                  </ul>
                </div>
                <Link className={styles.lightButton} to="/cennik#subscribe">
                  Rozpocznij teraz
                </Link>
              </div>
            </Col>
            <Col lg={8} className={styles.standard}>
              <div>
                <h2>Standard</h2>
                {prices[sliderIdx].price}
                <div className={styles.docRangeQuestion}>
                  Ile stron dokumentów przetwarzasz miesięcznie?
                </div>
                <Slider
                  min={0}
                  max={3}
                  railStyle={{
                    height: 10,
                    backgroundColor: "#c0c8cf",
                    boxShadow: "inset 0px 5px 6px -3px rgba(0,0,0,0.26)",
                  }}
                  trackStyle={{
                    height: 10,
                    backgroundColor: "#f4364c",
                    boxShadow: "inset 0px 5px 6px -3px rgba(0,0,0,0.26)",
                  }}
                  dotStyle={{
                    width: 14,
                    height: 14,
                    marginLeft: -7,
                    bottom: -8,
                    opacity: 0,
                  }}
                  handleStyle={{
                    width: 30,
                    height: 30,
                    marginTop: -10,
                    marginLeft: 0,
                    border: "1px solid #e0e0e0",
                    boxShadow: "0px 5px 6px -3px rgba(0,0,0,0.46)",
                    background: `white url(${sliderArrows}) no-repeat scroll 5px 7px`,
                  }}
                  defaultValue={0}
                  onChange={idx => setSliderIdx(idx)}
                  marks={{
                    0: <SliderLabel label="1000" />,
                    1: <SliderLabel label="2500" />,
                    2: <SliderLabel label="5000" />,
                    3: <SliderLabel label="Więcej" />,
                  }}
                />
                <div className={styles.features}>
                  <ul>
                    <li>
                      {prices[sliderIdx].pages} odczytanych stron na miesiąc w
                      pakiecie
                    </li>
                    <li>
                      Każda dodatkowa strona{" "}
                      <strong>{prices[sliderIdx].page}</strong>
                    </li>
                    <li>
                      <strong>Nieograniczona</strong> ilość użytkowników
                    </li>
                    <li>
                      <strong>Nieograniczona</strong> ilość podmiotów
                    </li>
                    <li>Możliwość eksportu do programów FK</li>
                    <li>Wyszukiwanie dowolnych dokumentów</li>
                  </ul>
                </div>
                <Link className={styles.lightButton} to="/cennik#subscribe">
                  Rozpocznij teraz
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Subscribe />
    </Layout>
  )
}

export default Cennik
